import { render, staticRenderFns } from "./R-score-widget.vue?vue&type=template&id=098ee5e1"
import script from "./R-score-widget.vue?vue&type=script&lang=js"
export * from "./R-score-widget.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Vinura\\Redem\\redem-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('098ee5e1')) {
      api.createRecord('098ee5e1', component.options)
    } else {
      api.reload('098ee5e1', component.options)
    }
    module.hot.accept("./R-score-widget.vue?vue&type=template&id=098ee5e1", function () {
      api.rerender('098ee5e1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/redem/datafiles/components/widgets/RScore/R-score-widget.vue"
export default component.exports