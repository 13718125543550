<script>
/* eslint-disable no-unused-vars */
// Services
import DatafileService from "@/api/services/datafile.service.js";
import appConfig from "@/app.config";
// Modals
import AuthService from "@/api/services/auth.service.js";
import ChangeDatafileStatusModal from "@/redem/datafiles/components/modals/change-datafile-status-modal.vue";
import CleanDataModal from "@/redem/datafiles/components/modals/clean-data-modal.vue";
import EditCleaningSettingsModal from "@/redem/datafiles/components/modals/edit-cleaning-settings-modal.vue";
import ExportWorksheetModal from "@/redem/datafiles/components/modals/export-worksheet-modal.vue";
import ManageDatafileCollaboratorsModal from "@/redem/datafiles/components/modals/manage-contributors-modal.vue";
import ManageReportModal from "@/redem/datafiles/components/modals/manage-datafile-report.vue";
import QuotaSettingsModal from "@/redem/datafiles/components/modals/quota-settings-modal.vue";
import UpdateDatafileModal from "@/redem/datafiles/components/modals/update-datafile-modal.vue";
import WeightDataModal from "@/redem/datafiles/components/modals/weight-data-modal.vue";
import CleanedResults from "@/redem/datafiles/components/tabs/cleaned-results.vue";
import RawResults from "@/redem/datafiles/components/tabs/raw-results";
import Layout from "@/router/layouts/main";
import PageHeader from "@/shared/components/page-header";
// Shared
import DataCleaningMethod from "@/shared/enums/dataCleaningMethods.js";
import { EventBus } from "@/shared/util/event-bus.js";
import { exportWorksheet } from "@/shared/util/helpers.js";
import dataImportService from "@/api/services/dataImport.service";

/**
 * Datafile Result
 */
export default {
  page: {
    title: "Datafile Analysis",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    RawResults,
    CleanedResults,
    CleanDataModal,
    WeightDataModal,
    ExportWorksheetModal,
    UpdateDatafileModal,
    QuotaSettingsModal,
    ManageReportModal,
    ChangeDatafileStatusModal,
    EditCleaningSettingsModal,
    ManageDatafileCollaboratorsModal
  },
  data() {
    return {
      title: "Datafile Analysis",
      titleTranslationKey: "datafileAnalysis",
      /*******  Data varibales ********/
      isCleaningApplyForTheDatafile: false,
      loggedInUser: null,
      dbDatafile: null,
      /******** Operational Variables *********/
      isDisplayChangeDatafileStatusModal: false,
      isDisplayRawResults: false,
      isDisplayCleanedResults: true,
      isDisplayCleanDataModal: false,
      isDisplayWeightDataModal: false,
      isDisplayExportWorksheetModal: false,
      isDisplayUpdateDatafileModal: false,
      isDisplayQuotaSettingsModal: false,
      isDisplayManageReportModal: false,
      datafileId: this.$route.query.datafileId,
      datafileTitle: "",
      isLiveCleaning: "",
      integration: null,
      isAllowedToImportData: true,
      certificateLevel: null,
      weightingSettings: {},
      cleaningSettings: {},
      cleaningMethod: null,
      markThreshold: null,
      projectiveQuestions: null,
      notUsedProjectiveQuestions: null,
      mountModals: false,
      datafileKey: null,
      metadata: {},
      exportWorksheet,
      isDisplayManageCollaboratorModal: false,
      showSecretKeyCopySuccessAlert: false,
      showSecretKeyCopyErrorAlert: false
    };
  },
  created() {
    EventBus.$on("showQuotaSettingsModal", () => {
      this.isDisplayQuotaSettingsModal = true;
    });

    EventBus.$on("showCleaningModal", () => {
      this.isDisplayCleanDataModal = true;
    });
  },
  async mounted() {
    await this.getDatafile(this.datafileId);

    // getLoggedInUser
    this.loggedInUser = await AuthService.getLoggedInUser();
    this.isAllowedToImportData = await dataImportService.checkAllowedForDataImport(1, this.loggedInUser.company.id);
    if (this.$route.query.activateRawTab) {
      this.clickOnRaw();
    }
  },
  computed: {
    computedItems() {
      var items = [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: this.$t("datafileResultGeneral.pageTitle"),
          active: true
        }
      ];
      return items;
    }
  },
  methods: {
    async reloadPage() {
      if (this.$route.query.activateRawTab) {
        await this.$router.push(
          `/datafile-result?datafileId=${this.datafileId}`
        );
      }

      window.location.reload();
    },
    async getDatafile(datafileID) {
      const datafile = await DatafileService.getDatafile(datafileID);
      this.dbDatafile = datafile;
      this.datafileTitle = datafile.title;
      this.isLiveCleaning = datafile.isLiveCleaning;
      this.integration = datafile.integration;
      this.weightingSettings = datafile.weightingMethod;
      this.metadata = datafile.metadata;
      this.datafileKey = datafile.datafileKey;
      this.certificateLevel = datafile.certificateLevel;
      this.cleaningSettings = datafile.cleaningSettings;
      this.cleaningMethod = datafile.cleaningMethod;
      this.markThreshold = datafile.markThreshold;

      if (!this.cleaningSettings)
        this.cleaningSettings = {
          scoreThreshold: null,
          targetSampleSize: null
        };
      if (!this.cleaningMethod)
        this.cleaningMethod = DataCleaningMethod.SCORE_THRESHOLD;

      this.isCleaningApplyForTheDatafile = datafile.isCleaned;

      this.mountModals = true;
    },
    clickWeightingData() {
      this.isDisplayWeightDataModal = true;
    },
    clickCleanData() {
      this.isDisplayCleanDataModal = true;
    },
    clickUpdateDatafile() {
      this.isDisplayUpdateDatafileModal = true;
    },
    clickOnRaw() {
      this.isDisplayRawResults = true;
      this.isDisplayCleanedResults = false;
    },
    clickOnCleaned() {
      this.isDisplayCleanedResults = true;
      this.isDisplayRawResults = false;
    },
    clickWorkSheet() {
      this.$router.push({
        path: "datafile-work-sheet",
        query: { datafileId: this.datafileId }
      });
    },
    async clickExportWorksheet() {
      this.isDisplayExportWorksheetModal = true;
    },
    clickQuotaSettings() {
      this.isDisplayQuotaSettingsModal = true;
      EventBus.$emit("showQuotaSettingsModal");
    },
    closeCleanDataModal() {
      this.isDisplayCleanDataModal = false;
    },
    closeWeightDataModal() {
      this.isDisplayWeightDataModal = false;
    },
    closeExportWorksheetModal() {
      this.isDisplayExportWorksheetModal = false;
    },
    async commitCleanData() {
      this.isDisplayCleanDataModal = false;
    },
    commitExportWorksheet(event) {
      this.exportWorksheet(event, this);
    },
    clickManageReport() {
      this.isDisplayManageReportModal = true;
    },
    clickChangeDatafileStatus() {
      this.isDisplayChangeDatafileStatusModal = true;
    },
    async copySecretKey() {
      async function delay(ms) {
        return new Promise((resolve) => {
            setTimeout(() => resolve(), ms);
          });
      }      
      const datafileSecretKey = this.metadata.datafileSecretKey;
      try {
        navigator.clipboard.writeText(datafileSecretKey);
        this.showSecretKeyCopySuccessAlert = true;
        await delay(3000);
        this.showSecretKeyCopySuccessAlert = false;
      } catch (e) {
        this.showSecretKeyCopyErrorAlert = true;  
      }
    },
    clickViewInstructions() {
      this.$router.push({
        path: "snippet-integration-instructions",
        query: { datafileId: this.datafileId, returnToResult: true }
      });
    },
    clickManageCollaborators() {
      this.isDisplayManageCollaboratorModal = true
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader
      :title="this.$t('datafileResultGeneral.pageTitle')"
      :items="computedItems"
    />

    <!-- Button Row -->
    <div class="row justify-content-between">
      <div class="col-md-3">
        <!-- Raw Results -->
        <b-button
          variant="primary"
          @click="clickOnRaw()"
          class="mr-2"
          v-if="isDisplayRawResults"
        >
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-poll-h"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("datafileResultGeneral.buttonRow.rawButton")
          }}</span></b-button
        >
        <b-button
          variant="light"
          @click="clickOnRaw()"
          class="mr-2"
          v-if="!isDisplayRawResults"
        >
          <span class="d-inline-block d-sm-none">
            <i class="fas fa-poll-h"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("datafileResultGeneral.buttonRow.rawButton")
          }}</span></b-button
        >
        <!-- Cleaned Results -->
        <b-button
          variant="primary"
          @click="clickOnCleaned()"
          v-if="isDisplayCleanedResults"
        >
          <span class="d-inline-block d-sm-none">
            <i class="far fa-question-circle"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("datafileResultGeneral.buttonRow.cleanedButton")
          }}</span>
        </b-button>
        <b-button
          variant="light"
          @click="clickOnCleaned()"
          v-if="!isDisplayCleanedResults"
        >
          <span class="d-inline-block d-sm-none">
            <i class="far fa-question-circle"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("datafileResultGeneral.buttonRow.cleanedButton")
          }}</span>
        </b-button>
      </div>
      <div class="col-md-4 text-center">
        <b-alert
        :show="showSecretKeyCopySuccessAlert"
        class="col-md-12"
        variant="success"
        >{{ $t("datafileResultGeneral.buttonRow.copied") }}</b-alert
      >
      </div>      
      <div class="col-md-5 text-right">
        <!-- Change datafile status -->
        <span
          class="text-primary mr-3 font-weight-medium"
          style="cursor: pointer"
          @click="clickChangeDatafileStatus()"
          v-if="this.isLiveCleaning"
        >
          <i class="bx bx-repost"></i>
          {{ $t("datafileResultGeneral.buttonRow.changeDatafileStatus") }}
        </span>
        <!-- Copy Secret Key -->
        <span v-if="this.secretKeyCopySuccess">
          <b-badge
            class="mr-2"
            style="font-size: 12px"
            variant="success"
          >
            {{ $t("datafileResultGeneral.buttonRow.copied") }}
          </b-badge></span
        >         
        <span
          class="text-primary mr-3 font-weight-medium"
          style="cursor: pointer"
          @click="copySecretKey()"
          v-if="this.isLiveCleaning"
        >
          <i class="bx bx-copy"></i>
          {{ $t("datafileResultGeneral.buttonRow.copySecretKey") }}
        </span>
        <!-- Export Button -->
        <span
          @click="clickExportWorksheet()"
          class="text-primary mr-3 font-weight-medium"
          style="cursor: pointer"
        >
          <i class="bx bx-export"></i>
          {{ $t("datafileResultGeneral.buttonRow.exportButton") }}
        </span>
        <!-- Cleaning Button -->
        <span
          @click="clickCleanData()"
          class="text-primary mr-3 font-weight-medium"
          style="cursor: pointer"
        >
          <i class="bx bx-brush"></i>
          {{ $t("datafileResultGeneral.buttonRow.cleaningAndMarkButton") }}
        </span>
        <!-- Other Options  -->
        <b-dropdown toggle-class="nav-btn" right variant="light">
          <template v-slot:button-content>
            <i class="bx bx-dots-horizontal-rounded font-size-20"></i>
          </template>
          <b-dropdown-item
            v-if="
              !this.isLiveCleaning
            "
            @click="clickUpdateDatafile()"
            ><i class="bx bx-reset mr-2"></i
            >{{
              $t("datafileResultGeneral.buttonRow.updateDatafile")
            }}</b-dropdown-item
          >
          <b-dropdown-item
            @click="clickQuotaSettings()"
            v-if="!this.isLiveCleaning"
            ><i class="bx bxs-pie-chart-alt-2 mr-2"></i
            >{{
              $t("datafileResultGeneral.buttonRow.quotaSettings")
            }}</b-dropdown-item
          >
          <b-dropdown-item
            @click="clickWeightingData()"
            v-if="!this.isLiveCleaning || this.isLiveCleaning==='STOPPED'"
          >
            <i class="bx bx-slider mr-2"></i>
            {{
              $t("datafileResultGeneral.buttonRow.weightingButton")
            }}</b-dropdown-item
          >
          <b-dropdown-item @click="clickManageReport()"
            ><i class="bx bxs-cog mr-2"></i>
            {{
              $t("datafileResultGeneral.buttonRow.manageReport")
            }}</b-dropdown-item
          >
          <b-dropdown-item
            @click="clickViewInstructions()"
            v-if="this.isLiveCleaning && this.integration"
          >
            <i class="bx bx-show mr-2"></i>
            {{
              $t("datafileResultGeneral.buttonRow.showIntegrationInstructions")
            }}
          </b-dropdown-item>
          <b-dropdown-item @click="clickManageCollaborators()">
            <i class="bx bxs-group mr-2"></i>
            {{
              $t("datafileResultGeneral.buttonRow.manageContributors")
            }}
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item @click="clickWorkSheet()"
            ><i class="bx bx-spreadsheet mr-2"></i>
            {{
              $t("datafileResultGeneral.buttonRow.worksheetButton")
            }}</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>

    <!-- Results Row -->
    <div class="row">
      <div class="col-12 mt-4">
        <RawResults v-if="isDisplayRawResults" />
        <CleanedResults v-if="isDisplayCleanedResults" />
      </div>
    </div>

    <!-- Clean Data Modal -->
    <CleanDataModal
      v-if="this.isDisplayCleanDataModal"
      :visible="
        (!this.isLiveCleaning || this.isLiveCleaning === 'STOPPED') &&
        this.isDisplayCleanDataModal
      "
      :datafileId="this.datafileId"
      @close="closeCleanDataModal"
      @reload="reloadPage()"
      @commitCleanData="commitCleanData"
    />

    <!-- Edit Cleaning Settings Modal -->
    <EditCleaningSettingsModal
      v-if="this.isDisplayCleanDataModal"
      :visible="
        this.isLiveCleaning === 'RUNNING' && this.isDisplayCleanDataModal
      "
      @close="isDisplayCleanDataModal = false"
    />

    <!-- Weighting Modal  -->
    <WeightDataModal
      v-if="this.mountModals"
      :visible="this.isDisplayWeightDataModal"
      @reload="reloadPage()"
      :datafileId="this.datafileId"
      @close="closeWeightDataModal"
      :weightingSettings="this.weightingSettings"
    />

    <!-- Export Modal  -->
    <ExportWorksheetModal
      :visible="this.isDisplayExportWorksheetModal"
      :datafileId="this.datafileId"
      :isLiveCleaning="this.isLiveCleaning"
      @close="closeExportWorksheetModal"
      @commitExportWorksheet="commitExportWorksheet"
    />

    <!-- Update Datafile Modal  -->
    <UpdateDatafileModal
      :visible="this.isDisplayUpdateDatafileModal"
      :datafileId="this.datafileId"
      :metadata="this.metadata"
      :oldDatafileKey="this.datafileKey"
      :isAllowedToImportData="this.isAllowedToImportData"
      @reload="reloadPage()"
      @close="isDisplayUpdateDatafileModal = false"
    />

    <!-- Quota Settings Modal -->
    <QuotaSettingsModal
      v-if="this.datafileId"
      :visible="this.isDisplayQuotaSettingsModal"
      :datafileId="this.datafileId"
      :datafileTitle="this.datafileTitle"
      :metadata="this.metadata"
      @reload="reloadPage()"
      @close="isDisplayQuotaSettingsModal = false"
    />

    <!-- Manage Report Modal -->
    <ManageReportModal
      :visible="this.isDisplayManageReportModal"
      :datafileId="this.datafileId"
      :datafileTitle="this.datafileTitle"
      @close="isDisplayManageReportModal = false"
      @reload="reloadPage()"
    />

    <!-- Change Datafile Status Modal -->
    <ChangeDatafileStatusModal
      v-if="isDisplayChangeDatafileStatusModal === true"
      :visible="isDisplayChangeDatafileStatusModal"
      :datafileObject="this.dbDatafile"
      @close="isDisplayChangeDatafileStatusModal = false"
      @reload="reloadPage()"
    />

    <!-- Manage Datafile Collaborators Modal -->
    <ManageDatafileCollaboratorsModal
      v-if="isDisplayManageCollaboratorModal === true"
      :visible="isDisplayManageCollaboratorModal"
      :datafileId="this.datafileId"
      :datafileOwner="this.dbDatafile.owner"
      @close="isDisplayManageCollaboratorModal = false"
      @reload="reloadPage()"
    />
  </Layout>
</template>
