import { render, staticRenderFns } from "./CS-average-prediction-widgets.vue?vue&type=template&id=684ebe97"
import script from "./CS-average-prediction-widgets.vue?vue&type=script&lang=js"
export * from "./CS-average-prediction-widgets.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Vinura\\Redem\\redem-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('684ebe97')) {
      api.createRecord('684ebe97', component.options)
    } else {
      api.reload('684ebe97', component.options)
    }
    module.hot.accept("./CS-average-prediction-widgets.vue?vue&type=template&id=684ebe97", function () {
      api.rerender('684ebe97', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/redem/datafiles/components/widgets/CS/CS-average-prediction-widgets.vue"
export default component.exports